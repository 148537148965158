import axios from 'axios'
import request from '../utils/request'


export function postRequest(url, params) {
    return request.post(url, {
        ...params
    })
}

export function createRoomToken(room, userId, type) {
    return request.post('/Class_online/index.php', {
        function: 'createRoomToken',
        room,
        userId,
        type
    })
}

// export function loginByEducator(email, pwd) {
//     return request.post('./index.php', {
//         function: 'loginByEducator',
//         email,
//         pwd
//     })
// }

export function loginByEmployee(email, pwd) {
    return request.post('./index.php', {
        function: 'loginByEmployee',
        email,
        pwd
    })
}

export function loginByStudent(student_email, student_pwd) {
    return request.post('./WAO_Resource/index.php', {
        function: 'loginByStudent',
        student_email,
        student_pwd
    })
}

export function createLoginPinCode(student_email) {
    return request.post('./WAO_Resource/index.php', {
        function: 'createLoginPinCode',
        student_email
    })
}
export function verifyLoginPin(student_email, pin) {
    return request.post('./WAO_Resource/index.php', {
        function: 'verifyLoginPin',
        student_email,
        pin
    })
}

export function getUserNames(project_code) {
    return request.post('./index.php', {
        function: 'getUserNames',
        project_code
    })
}

export function getMyEvents(student_id) {
    return request.post('./index.php', {
        function: 'getMyEvents',
        student_id
    })
}

export function getEventsByEducator(educator_id, project_code) {
    return request.post('./index.php', {
        function: 'getEventsByEducator',
        educator_id,
        project_code
    })
}

export function getMyZhiboRooms(student_id, project_code) {
    return request.post('./index.php', {
        function: 'getWAOExamRooms',
        student_id,
        project_code
    })
}

export function getExerciseTemplateByStudent(template_id, user_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplateByStudent',
        template_id,
        user_id
    })
}

//废弃，不要用了
export function uploadAnswers(answers, student_id, template_id) {
    return request.post('./index.php', {
        function: 'uploadAnswers',
        answers,
        student_id,
        template_id
    })
}
export function uploadAnswersV1(project_code, answers, student_id, template_id, app_id, ip) {
    return request.post('./index.php', {
        function: 'uploadAnswers',
        project_code,
        answers,
        student_id,
        template_id,
        app_id,
        ip
    })
}

export function changeVisibleLog(student_id, project_code, template_id) {
    return request.post('/RE_Arena/index.php', {
        function: 'changeVisibleLog',
        student_id,
        project_code,
        template_id
    })
}

export function getTestVisibleChangeCount(template_id, student_id, project_code) {
    return request.post('/RE_Arena/index.php', {
        function: 'getTestVisibleChangeCount',
        template_id,
        student_id,
        project_code
    })
}

export function submitAnswer(question_id, template_id, choice, student_id, ip, test_event, project_code, answer_img_url) {
    return request.post('./index.php', {
        function: 'submitAnswer',
        question_id,
        template_id,
        choice,
        student_id,
        ip,
        test_event,
        project_code,
        answer_img_url
    })
}
export function uploadAnswersV2(project_code, student_id, template_id, app_id, ip, test_event, test_type) {
    return request.post('./index.php', {
        function: 'uploadAnswersByLogs',
        project_code,
        student_id,
        template_id,
        app_id,
        ip,
        test_event,
        test_type
    })
}
export function uploadAnswersV3(project_code, student_id, template_id, app_id, ip, test_event, test_type, answers) {
    return request.post('./index.php', {
        function: 'uploadAnswersByLogsNew',
        project_code,
        student_id,
        template_id,
        app_id,
        ip,
        test_event,
        test_type,
        answers
    })
}
export function saveAudioRecord(user_id, section_id, template_id) {
    return request.post('./index.php', {
        function: 'saveAudioRecord',
        user_id,
        section_id,
        template_id
    })
}

export function getUser(user_id, user_type, project_code) {
    return request.post('./index.php', {
        function: 'getUser',
        user_id,
        user_type,
        project_code
    })
}
export function getEducator(user_id, project_code) {
    return request.post('./index.php', {
        function: 'getEducator',
        user_id,
        project_code
    })
}

export function getEmployee(user_id) {
    return request.post('./index.php', {
        function: 'getEmployee',
        user_id
    })
}



export function updateUserAvatar(user_id, avatar_url) {
    return request.post('./index.php', {
        function: 'updateUserAvatar',
        user_id,
        avatar_url
    })
}


export function uploadPic(param) {
    return request.post('./index.php', param)
}

export function listRoomUsers(room) {
    return request.post('./index.php', {
        function: 'listRoomUsers',
        room
    })
}

export function getSchoolScoresByEvent(event) {
    return request.post('./index.php', {
        function: 'getSchoolScoresByEvent',
        event
    })
}

export function getAllZhiboEvents(project_code) {
    return request.post('./index.php', {
        function: 'getAllZhiboEvents',
        project_code
    })
}

export function getAllExams() {
    return request.post('./index.php', {
        function: 'getAllExams'
    })
}

export function getApplicationsByEvent(event) {
    return request.post('./index.php', {
        function: 'getApplicationsByEvent',
        event
    })
}

export function getScoresByEvent(event, project_code) {
    return request.post('./index.php', {
        function: 'getScoresByEvent',
        event,
        project_code
    })
}

export function getScoresSelf(event, user_id, project_code) {
    return request.post('./index.php', {
        function: 'getScoresSelf',
        event,
        user_id,
        project_code
    })
}

export function checkTestLinkOpen(student_id, name) {
    return request.post('./index.php', {
        function: 'checkTestLinkOpen',
        student_id,
        name
    })
}
export function checkZhiboOpen(student_id, roomId, project_code, event) {
    return request.post('./index.php', {
        function: 'checkZhiboOpen',
        student_id,
        roomId,
        project_code,
        event
    })
}

export function checkZhiboOpenQian(student_id, roomId, project_code) {
    return request.post('./index.php', {
        function: 'checkZhiboOpenQian',
        student_id,
        roomId,
        project_code
    })
}



export function checkPeixun() {
    return request.post('./index.php', {
        function: 'checkPeixun'
    })
}

export function getApplicationsByRoom(roomId) {
    return request.post('./index.php', {
        function: 'getApplicationsByRoom',
        roomId
    })
}
//废弃了
export function uploadTimeStamp(student_id, template_id, stamp) {
    return request.post('/RE_Arena/index.php', {
        function: 'uploadTimeStamp',
        student_id,
        template_id,
        stamp
    })
}

export function uploadTimeStampV1(student_id, template_id, stamp, project_code) {
    return request.post('/RE_Arena/index.php', {
        function: 'uploadTimeStamp',
        student_id,
        template_id,
        stamp,
        project_code
    })
}

export function getTimeStamp(student_id, template_id) {
    return request.post('/RE_Arena/index.php', {
        function: 'getTimeStamp',
        student_id,
        template_id
    })
}

export function getGroupAwards(project_code, event) {
    return request.post('./index.php', {
        function: 'getGroupAwards',
        project_code,
        event
    })
}

export function getAMGroupAwards(project_code, event) {
    return request.post('./index.php', {
        function: 'getAMGroupAwards',
        project_code,
        event
    })
}


export function getAwards(project_code, application_event) {
    return request.post('./index.php', {
        function: 'getAwards',
        project_code,
        application_event
    })
}

export function getAMAllScores(project_code, application_event) {
    return request.post('./index.php', {
        function: 'getAMAllScores',
        project_code,
        application_event
    })
}

export function getAMMyAward(project_code, student_id) {
    return request.post('./index.php', {
        function: 'getAMMyAward',
        project_code,
        student_id
    })
}


export function getMyAward(project_code, student_id) {
    return request.post('./index.php', {
        function: 'getMyAward',
        project_code,
        student_id
    })
}

export function getSessionList(project_code) {
    return request.post('./index.php', {
        function: 'getSessionList',
        project_code
    })
}
export function getSessionEvents(project_code, session, student_id) {
    return request.post('./index.php', {
        function: 'getSessionEvents',
        project_code,
        session,
        student_id

    })
}
export function getExerciseTestTemplate(event, project_code, type, test_event, template_id) {
    return request.post('./index.php', {
        function: 'getExerciseTestTemplate',
        event,
        project_code,
        type,
        test_event,
        template_id
    })
}
export function uploadExamShot(student_id, project_code, template_id, camera_pic, screen_pic) {
    return request.post('/RE_Arena/index.php', {
        function: 'uploadExamShot',
        student_id,
        project_code,
        template_id,
        camera_pic,
        screen_pic
    })
}


export function findMyTranscript(student_id, project_code) {
    return request.post('./index.php', {
        function: 'findMyTranscript',
        student_id,
        project_code
    })
}

export function getEventScores(event, project_code) {
    return request.post('./index.php', {
        function: 'getEventScores',
        event,
        project_code
    })
}

export function getProjectEvent(project_code) {
    return request.post('./index.php', {
        function: 'getProjectEvent',
        project_code
    })
}

export function getExerciseTemplateByStudentReview(template_id, student_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplateByStudentReview',
        template_id,
        student_id
    })
}

export function checkIsUploadTest(template_id, student_id) {
    return request.post('./index.php', {
        function: 'checkIsUploadTest',
        template_id,
        student_id
    })
}

export function initExamShotTimePoint(student_id, project_code, template_id) {
    return request.post('/RE_Arena/index.php', {
        function: 'initExamShotTimePoint',
        student_id,
        project_code,
        template_id
    })
}

export function getHomeAnnouncements() {
    return request.post('./index.php', {
        function: 'getHomeAnnouncements'
    })
}

export function uploadExamShotNew(param) {
    return request.post('/RE_Arena/index.php',
        param
    )
}

export function checkTranscriptOpen(project_code) {
    return request.post('./index.php', {
        function: 'checkTranscriptOpen',
        project_code
    })
}

export function test(project_code) {
    return request.post('./index.php', {
        function: 'test',
        project_code
    })
}
export function uploadTestHeartBeat(student_id, template_id, status) {
    return request.post('/RE_Arena/index.php', {
        function: 'uploadTestHeartBeat',
        student_id,
        template_id,
        status
    })
}
export function studentSignWithTemplate(student_id, template_id, project_code) {
    return request.post('./index.php', {
        function: 'studentSignWithTemplate',
        student_id,
        template_id,
        project_code
    })
}


export function studentUpdateTemplateWithIndex(student_id, template_id, project_code, index) {
    return request.post('./index.php', {
        function: 'studentUpdateTemplateWithIndex',
        student_id,
        template_id,
        project_code,
        index
    })
}


export function uploadStudentIdCard(student_id, template_id, project_code, pic_url) {
    return request.post('./index.php', {
        function: 'uploadStudentIdCard',
        student_id,
        template_id,
        project_code,
        pic_url
    })
}
export function getStudentIdCard(student_id, template_id, project_code) {
    return request.post('./index.php', {
        function: 'getStudentIdCard',
        student_id,
        template_id,
        project_code
    })
}


// export function getStudentAwards(project_code, student_id) {
//     return request.post('./index.php', {
//         function: 'getStudentAwards',
//         project_code,
//         student_id
//     })
// }

// export function getStudentTranscripts(project, student_id) {
//     return request.post('./index.php', {
//         function: 'getStudentTranscripts',
//         project,
//         student_id
//     })
// }


export function checkUserInvigilate(project_code, student_id) {
    return request.post('./index.php', {
        function: 'checkUserInvigilate',
        project_code,
        student_id
    })
}


export function getExamReadyTime() {
    return request.post("./index.php", {
        function: "getExamReadyTime",
    });
}


export function getLectureTip(student_id, project_code) {
    return request.post("./index.php", {
        function: "getLectureTip",
        student_id,
        project_code
    });
}





export function getResidueTime(project_code, student_id, event_name, videoTime) {
    return request.post('./index.php', {
        function: 'getResidueTime',
        project_code,
        student_id,
        event_name,
        videoTime
    })
}

export function getGroupId(id) {
    return request.post("./index.php", {
        function: "getGroupId",
        id
    });
}
export function saveVideoRecordPlayTime(student_id, content_id, project_code, stamp) {
    return request.post("./index.php", {
        function: "saveVideoRecordPlayTime",
        student_id,
        content_id,
        project_code,
        stamp
    });
}
export function getVideoRecordTime(student_id, content_id, project_code) {
    return request.post("./index.php", {
        function: "getVideoRecordTime",
        student_id,
        content_id,
        project_code
    });
}
export function getProjectInfo(project_code) {
    return request.post("./index.php", {
        function: "getProjectInfo",
        project_code
    });
}
export function getQiniuToken() {
    return request.post('./index.php', {
        function: 'getQiniuToken'
    })
}


//资源中心部分

export function getStudentScores(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentScores' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function saveImportData(paramInfo) {

    return axios.post('./WAO_Resource/index.php', paramInfo)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountApplicationsProjectCode(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountApplicationsProjectCode' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getStudentScoreApplications(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentScoreApplications' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getEventProjects(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getEventProjects' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getCertificate(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getCertificate' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getTestResults(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getTestResults' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountMessages(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountMessages' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateStudentInDatabase(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateStudentInDatabase' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function changeEvents(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'changeEvents' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountMaterialList(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountMaterialList' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getApplicationsInfoByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsInfoByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountApplicationsByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountApplicationsByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getSchoolInfoByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getSchoolInfoByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getStudentMessagesByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentMessagesByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getApplicationsProjectCodeByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsProjectCodeByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountApplications(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountApplications' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getAccountsByProvince(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountsByProvince' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getProjectEvents(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getProjectEvents' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function addNewApplicationForm(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'addNewApplicationForm' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function addPackageApplication(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'addPackageApplication' })
    return axios.post('./WAO_Resource/index.php', param)
}

export function getMaterialList(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getMaterialList' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getApplicationsByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getApplicationsByPackage(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsByPackage' })
    return axios.post('./WAO_Resource/index.php', param)
}

export function getApplicationsByPackageStudent(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsByPackageStudent' })
    return axios.post('./WAO_Resource/index.php', param)
}


export function getMyMessages(id, project_code) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getMyMessages',
        id,
        project_code
    })
}

export function sendMessage(student_id, type, sender_type, content, project_code, template_name) {
    return request.post('./WAO_Resource/index.php', {
        function: 'sendMessage',
        student_id,
        type,
        sender_type,
        content,
        project_code,
        template_name
    })
}

export function changePassword(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'changePassword' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getOrder(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getOrder' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getTeamInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getTeamInfo' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getStudentInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentInfo' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateStudentInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateStudentInfo' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getContactsByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getContactsByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getStudentByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccountApplication(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateAccountApplication' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateContactByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateContactByAccount' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getSchoolInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getSchoolInfo' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getPaypal(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getPaypal' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
// export function paypalSucceedBack(paramInfo) {
//   let param = Object.assign({},paramInfo,{function: 'paypalSucceedBack'})
//   return axios.post('./WAO_Resource/index.php', param)
//     .then(function (response) {
//       return response.data
//     })
//     .catch(function (error) {
//       console.log(error);
//     })
// }

export function getNotice(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getNotice' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getStudentApplications(student_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentApplications',
        student_id
    })
}
// export function loginByStudent({
//     student_email,
//     student_pwd,
//     project_code
// }) {
//     return axios.post('./WAO_Resource/index.php', {
//             function: 'loginByStudent',
//             student_pwd,
//             student_email,
//             project_code
//         })
//         .then(function(response) {
//             return response.data
//         })
//         .catch(function(error) {
//             console.log(error);
//         })
// }

// export function loginByContact({
//     contact_email,
//     contact_pwd,
//     project_code
// }) {
//     return axios.post('./WAO_Resource/index.php', {
//             function: 'loginByContact',
//             contact_pwd,
//             contact_email,
//             project_code
//         })
//         .then(function(response) {
//             return response.data
//         })
//         .catch(function(error) {
//             console.log(error);
//         })
// }

export function sendNewPwd({
    email,
    type
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'sendNewPwd',
            email,
            type,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function loginByAccountAdmin({
    account_email,
    account_pwd
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'loginByAccountAdmin',
            account_email,
            account_pwd,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccount(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateAccount' })
    console.log(param)
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccountContact(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateAccountContact' })
    console.log(param)
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccountProject(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateAccountProject' })
    console.log(param)
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateTemplate(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateTemplate' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updatetemplateblock(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updatetemplateblock' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccountStudent(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateAccountStudent' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getCollegeProjects(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'getCollegeProjects' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function addApplication(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'addApplication' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getNextProject(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'getNextProject' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function addNewProjectApplication(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'addNewProjectApplication' })
    return axios.post('./WAO_Resource/index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function loginByAccount({ account_email, account_pwd }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'loginByAccount',
            account_pwd,
            account_email,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountContact({ id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getAccountContact',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountContacts({ account_id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getAccountContacts',
            account_id,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccount({ account_id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getAccount',
            account_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function gettemplates({ account_id }) {
    return axios.post('./WAO_Resource/index.php', { function: 'gettemplates', account_id: account_id })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function gettemplate({ template_id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'gettemplate',
            template_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function gettemplateblocks({ id }) {
    return axios.post('./WAO_Resource/index.php', { function: 'gettemplateblocks', id: id })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function gettemplateblock({ id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'gettemplateblock',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountStudent({ id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getAccountStudent',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccounts() {
    return axios.post('./WAO_Resource/index.php', { function: 'getAccounts' })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountProjects({ account_id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getAccountProjects',
            account_id,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountProject({ id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getAccountProject',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getInvoiceInfo(id) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getInvoiceInfo',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getAccountStudents({ account_id }) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getAccountStudents',
            account_id,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}



export function getContents({
    session_id,
    student_id
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getContents',
            session_id,
            student_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getContent({
    content_id,
    student_id
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getContent',
            content_id,
            student_id

        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function resetQuiz(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'resetQuiz' })
    return axios.post('./WAO_Resource/index.php', param).then(function(response) {
        return response.data
    }).catch(function(error) {
        console.log(error);
    })
}

export function uploadQuiz({
    quiz_id,
    student_id,
    quiz_answer,
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'uploadQuiz',
            quiz_id,
            student_id,
            quiz_answer,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getStudentProjects({
    student_id
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getStudentProjects',

            student_id,

        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getTemplate({
    template_id
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getTemplate',
            template_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getStudentBlock({
    team_code,
    project_id,
    student_id,
    block_id
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getStudentBlock',
            team_code,
            project_id,
            student_id,
            block_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function updateStudentBlock({
    team_code,
    project_id,
    account_id,
    block_id,
    block_content
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'updateStudentBlock',
            team_code,
            project_id,
            account_id,
            block_id,
            block_content
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getProjectTeamcodes({
    project_id
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'getProjectTeamcodes',
            project_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function loginByEducator({
    educator_pwd,
    educator_email,
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'loginByEducator',
            educator_pwd,
            educator_email,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function uploadStaticBlockAttach({
    block_id,
    project_id,
    account_id,
    team_code,
    attach_name,
    attach_type
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: 'uploadStaticBlockAttach',
            block_id,
            project_id,
            account_id,
            team_code,
            attach_name,
            attach_type
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function netService({
    functionName,
    ...p
}) {
    return axios.post('./WAO_Resource/index.php', {
            function: functionName,
            ...p
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function upload({
    param,
    config
}) {
    console.log(param)
    return axios.post('./WAO_Resource/index.php',
            param, config)
        .then(response => {
            console.log(response.data);
            return response.data
        })
}

export function getCertifiTemplatesByProject({ project_id }) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getCertifiTemplatesByProject',
        project_id
    }).then(function(response) {
        return response.data
    }).catch(function(error) {
        console.log(error)
    })
}

export function export_csv({ data, title, file_name }) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'export_csv',
        data,
        title,
        file_name
    }).then(function(response) {
        return response
    }).catch(function(error) {
        console.log(error)
    })
}

export function paypalSucceedBack(application_id, data) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'paypalSucceedBack',
        application_id,
        data
    }).then(function(response) {
        return response
    }).catch(function(error) {
        console.log(error)
    })
}


export function getLoginProject(project_code, user_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getLoginProject',
        project_code,
        user_id
    })
}

export function saveImportDataNew(contact_id, project_code, data) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'saveImportDataNew',
        contact_id,
        project_code,
        data
    })
}

export function saveImportDataPackage(contact_id, project_code, data) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'saveImportDataPackage',
        contact_id,
        project_code,
        data
    })
}
export function getStudentApplication(student_id, project_code) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getStudentApplication',
        student_id,
        project_code
    })
}


export function saveTeamCode(applications) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'saveTeamCode',
        applications
    })
}

export function getProjects() {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getProjects'
    })
}


export function getProjectDetail(project_code, student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getProjectDetail',
        project_code,
        student_id
    })
}

export function applyEcoplore(project_id, student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'applyEcoplore',
        project_id,
        student_id
    })
}

export function getEducatorProjects(educator_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getEducatorProjects',
        educator_id
    })
}

export function getEducatorCanProjects(educator_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getEducatorCanProjects',
        educator_id
    })
}

export function applyProjectByEducator(educator_id, project_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'applyProjectByEducator',
        educator_id,
        project_id
    })
}

export function getProjectsByPackageApplication(package_application_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getProjectsByPackageApplication',
        package_application_id
    })
}

export function initApplicationInPackage(package_application_id, project_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'initApplicationInPackage',
        package_application_id,
        project_id
    })
}

export function cancelApplication(application_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'cancelApplication',
        application_id
    })
}

export function getMyFinishedHours(student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getMyFinishedHours',
        student_id
    })
}

export function getSessions(class_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getSessions',
        class_id
    })
}

export function getContentMessages(user_id, content_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getContentMessages',
        user_id,
        content_id
    })
}

export function updateContentMessage(user_id, content_id, content) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'updateContentMessage',
        user_id,
        content_id,
        content
    })
}

export function getStudentPackages(student_id, project_code) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getStudentPackages',
        student_id,
        project_code
    })
}
export function getPackageApplication(id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getPackageApplication',
        id
    })
}
export function getPackageOrder(application_id, content, check_status) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getPackageOrder',
        application_id,
        content,
        check_status
    })
}


export function getOxPayment(application_id, content, check_status) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getOxPayment',
        application_id,
        content,
        check_status
    })
}


export function getMoreOxPayment(application_ids) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getMoreOxPayment',
        application_ids,
    })
}



export function getStudentCommitAttaches(student_id, project_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getStudentCommitAttaches',
        student_id,
        project_id
    })
}

export function updateCommitAttach(param) {
    return axios.post('./WAO_Resource/index.php', param)
}

export function removeCommitAttach(id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'removeCommitAttach',
        id
    })
}


export function getCertificatesInfo(student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getCertificatesInfo',
        student_id
    })
}

export function getStudentAppList(student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getStudentAppList',
        student_id
    })
}

export function getThinkingQuestionsList(student_id, station_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getThinkingQuestionsList',
        student_id,
        station_id
    })
}


export function getThinkingQuestionsDetail(student_id, question_id, project_code) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getThinkingQuestionsDetail',
        student_id,
        question_id,
        project_code
    })
}


export function updateThinkingQuestionsAnswer(student_id, question_id, project_code, answer, imgs) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'updateThinkingQuestionsAnswer',
        student_id,
        question_id,
        project_code,
        answer,
        imgs
    })
}



export function getAccountScores(user_id, project_code) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getAccountScores',
        user_id,
        project_code
    })
}

export function getSchoolAwards(user_id, project_code) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getSchoolAwards',
        user_id,
        project_code
    })
}


export function getProjectConversionApps(student_id, project_code) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getProjectConversionApps',
        student_id,
        project_code
    })
}

export function getStripeUrl(application_id, content, check_status) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getStripeUrl',
        application_id,
        content,
        check_status
    })
}



export function getPaymentByApplication(application_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getPaymentByApplication',
        application_id
    })
}
export function getStudentAwards(project_code, student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getStudentAwards',
        project_code,
        student_id
    })
}
export function getStudentAwardsV1(project_code, student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getStudentAwardsV1',
        project_code,
        student_id
    })
}
export function getStudentTranscripts(project, student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getStudentTranscripts',
        project,
        student_id
    })
}

export function saveAccountForm(form) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'saveAccountForm',
        form
    })
}

export function getAccountByContact(contact_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getAccountByContact',
        contact_id
    })
}

export function sendWECLoginCode(student_email) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'sendWECLoginCode',
        student_email
    })
}

export function loginByWECCode(student_email, student_code) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'loginByWECCode',
        student_email,
        student_code
    })
}

export function getRegistrationInfo(student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getRegistrationInfo',
        student_id
    })
}
export function addWecRegistrationApplication(student_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'addWecRegistrationApplication',
        student_id
    })
}

export function removeApplication(id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'removeApplication',
        id
    })
}


export function getQNToken() {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getQNToken'
    })
}

export function uploadStudentTuitionTier(student_id, project_code, url) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'uploadStudentTuitionTier',
        student_id,
        project_code,
        url
    })
}


export function getDataList(userId) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getDataList',
        userId
    })
}


export function getAccountDataList(project_code, user_id) {
    return axios.post('./WAO_Resource/index.php', {
        function: 'getAccountDataList',
        project_code,
        user_id
    })
}

//做题相关

export function getStudentClasses(student_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentClasses',
        student_id
    })
}

export function checkAndInitClassRecord(student_id, class_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'checkAndInitClassRecord',
        student_id,
        class_id
    })
}

export function getClassCatalogue(student_id, class_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getClassCatalogue',
        student_id,
        class_id
    })
}
export function submitLearningAnswer(params) {
    return request.post('./WAO_Resource/index.php', {
        function: 'submitLearningAnswer',
        student_id: params.student_id,
        class_id: params.class_id,
        session_id: params.session_id,
        content_id: params.content_id,
        quiz_id: params.quiz_id,
        quiz_index: params.quiz_index,
        answer: params.answer,
        correct: params.correct,
        content_index: params.content_index,
        session_index: params.session_index,
    })
}

export function requestClassRecordData(params) {
    return request.post('./WAO_Resource/index.php', {
        function: 'requestClassRecordData',
        type: params.type,
        student_id: params.student_id,
        record_id: params.record_id,
        session_id: params.session_id,
        content_id: params.content_id,
        quiz_index: params.quiz_index,
        content_index: params.content_index,
        session_index: params.session_index
    })
}
export function collectionQuiz(params) {
    return request.post('./WAO_Resource/index.php', {
        function: 'collectionQuiz',
        student_id: params.student_id,
        class_id: params.class_id,
        session_id: params.session_id,
        content_id: params.content_id,
        quiz_id: params.quiz_id,
        note: params.note
    })
}

export function removeCollectionQuiz(collection_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'removeCollectionQuiz',
        collection_id
    })
}

export function getCollectionList(student_id, class_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getCollectionList',
        student_id,
        class_id
    })
}

export function searchCollection(student_id, class_id, key_word) {
    return request.post('./WAO_Resource/index.php', {
        function: 'searchCollection',
        student_id,
        class_id,
        key_word
    })
}
export function getStudentErrorList(student_id, class_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentErrorList',
        student_id,
        class_id
    })
}

export function getStudentValidPasses(application_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentValidPasses',
        application_id
    })
}


export function verifyPass(pass_id, application_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'verifyPass',
        pass_id,
        application_id
    })
}

export function getApplicationInfoNew(application_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getApplicationInfoNew',
        application_id
    })
}


export function updateBulletin(id, project_code, date, title, type, content) {
    return request.post('./WAO_Resource/index.php', {
        function: 'updateBulletin',
        id,
        project_code,
        date,
        title,
        type,
        content
    })
}


export function getBulletin(student_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getBulletin',
        student_id
    })
}

export function deleteBulletin(id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'deleteBulletin',
        id
    })
}


export function getStudentPurchasdPasses(student_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentPurchasdPasses',
        student_id
    })
}


export function getWAOApplications() {
    return request.post('./WAO_Resource/index.php', {
        function: 'getWAOApplications'
    })
}


export function aiQuestion(contents) {
    return request.post('./WAO_Resource/index.php', {
        function: 'aiQuestion',
        contents,
    })
}

export function getCompleteExamProjects() {
    return request.post('./WAO_Resource/index.php', {
        function: 'getCompleteExamProjects',
    })
}

export function getCompleteExamProjectsV2() {
    return request.post('./WAO_Resource/index.php', {
        function: 'getCompleteExamProjectsV2',
    })
}

export function updateProjectScoreAwardSetting(project_id, key, value) {
    return request.post('./WAO_Resource/index.php', {
        function: 'updateProjectScoreAwardSetting',
        project_id,
        key,
        value
    })
}


export function updateProjectInfo(project_id, registration_status) {
    return request.post('./WAO_Resource/index.php', {
        function: 'updateProjectInfo',
        project_id,
        registration_status
    })
}

export function updateProjectSubmission(project_id, submission_status) {
    return request.post('./WAO_Resource/index.php', {
        function: 'updateProjectSubmission',
        project_id,
        submission_status
    })
}


export function getPeojectScores(project_code, pdline, year) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getPeojectScores',
        project_code,
        pdline,
        year
    })
}

export function getProjectByCode(project_code) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getProjectByCode',
        project_code
    })
}